<template>
  <div id="app">
    <!-- <Home></Home> -->
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
</style>

<script>
// import Home from 'views/Home.vue'

// import { getAsideMenuData } from '/src/api/aside.js'

export default {
  components: {
    // Home,
  }

}
</script>

<style lang="scss" scoped>
#app {
  height: 100vh;
}
</style>
