
export default {
  state: {
    // navbar被选中的index
    navbar_index:'1'
  },

  mutations: {
    setNavBarIndex (state, index) {
      state.navbar_index = index
    }
  },
  actions: {}
}
