export default {
  state: {
    // 点击的菜单
    currentMenu: {
      index: 1,
      path: '/dashboard',
      name: 'Dashboard',
      label: '仪表盘'

    },
    // tab列表
    tabsList: [
      {
        index: 1,
        path: '/dashboard',
        name: 'Home',
        label: '首页',
        icon: 'setting'
      }
    ]
  },
  mutations: {
    selectMenu (state, val) {
      if (val.name !== 'Home') {
        state.currentMenu = val

        const result = state.tabsList.findIndex(item => item.name === val.name)
        result === -1 ? state.tabsList.push(val) : ''
        // if (!state.tabsList.includes(val.name)) {
        //   state.tabsList.push(val.name)
        // }
      } else {
        state.currentMenu = {}
      }
    },
    // 关闭tab标签方法
    closeTab (state, val) {
      const lastResult = state.tabsList.lastIndexOf(item => item.name === val.name)
      const result = state.tabsList.findIndex(item => item.name === val.name)
      lastResult === -1 ? state.tabsList.splice(result, 1) : ''
    }
  },
  actions: {
  }

}
