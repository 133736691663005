import Vue from 'vue'
import Vuex from 'vuex'
import Persistedstate from 'vuex-persistedstate' // 持续状态

import tab from './tab'
import aside from './aside.js'
import navbar from './navbar.js'


Vue.use(Vuex)

const dataState = Persistedstate({
  // 全部菜单
  paths: ['aside.menu', 'tab.currentMenu', 'tab.tabsList'],
  storage: window.sessionStorage
})

export default new Vuex.Store({
  modules: {
    tab,
    aside,
    navbar
  },
  plugins: [dataState]
})
