export default {
  install (Vue, options) {
    // 公共方法 globalDeepCloneData 作用：处理对象的 key值, state的value值  copyInKeyMap 为1 表示不全复制 ；为 0 表示全部复制
    Vue.prototype.globalDeepCloneData = function (data, keyMap, copyInKeyMap = 0) {
      return data.map(obj => {
        // 对象处理
        return Object.keys(obj).reduce((newData, key) => {     
          let newKey = ''
          if (copyInKeyMap == true) {
            newKey = keyMap[key]
          } else {
            newKey = keyMap[key] || key
            
          } 
          if (typeof obj[key] === 'object') {
            if (obj[key] instanceof Array && obj[key].length > 0) {
              newData[newKey] = this.globalDeepCloneData(obj[key], keyMap, copyInKeyMap)
            }
          } else {
            newData[newKey] = obj[key]
          }
          return newData
        }, {})
      })
    }
  }
}
