import Vue from 'vue'
import VueRouter from 'vue-router'
// import { component } from "vue/types/umd";

Vue.use(VueRouter)

// 阻止router重复点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// // 如果你的改了push还是没有生效，可以考虑改replace方法
// // 修改路由replace方法,阻止重复点击报错
// const originalReplace = VueRouter.prototype.replace;
// VueRouter.prototype.replace = function replace (location) {
//   return originalReplace.call(this, location).catch(err => err);
// };

const Login = () => import('views/login/index.vue')

const Home = () => import('views/Home.vue')
// 仪表盘
const Dashboard = () => import('views/dashboard/Dashboard.vue')

//订单管理
const OrderManagement = () => import('views/orderManagement/OrderManagement.vue')
const AccommodationOrder = ()=> import('views/orderManagement/children/accommodationOrder.vue')
const tourOrder = ()=> import('views/orderManagement/children/tourOrder.vue')
const privilegeOrder = ()=> import('views/orderManagement/children/privilegeOrder.vue')

// 基础管理
const Basis = () => import('views/basis/Basis.vue')
const BrandManage = () => import('views/basis/children/BrandManage.vue')
const GoodsManage = () => import('views/basis/children/GoodsManage.vue')
const ScenicElements = () => import('views/basis/children/ScenicElements.vue')
const PrivilegeClassify = () => import('views/basis/children/PrivilegeClassify.vue')
const card = () => import('views/basis/children/card.vue')
const shareProfit = () => import('views/basis/children/shareProfit.vue')
const TourismClassify = () => import('views/basis/children/TourismClassify.vue')
const tourArea = () => import('views/basis/children/tourArea.vue')
//组织架构
const Organize = () => import('views/organize/Organize')
const User = () => import('views/organize/children/User.vue')
const Role = () => import('views/organize/children/Role')
const Menulist = () => import('views/organize/children/Menulist')
const Power = () => import('views/organize/children/Power')
const Supplier = () => import('views/organize/children/Supplier')
// 内容管理
const ContentManage = () => import('views/contentManage/contentManage.vue')
const ArticleManage = () => import('views/contentManage/children/ArticleManage.vue')

// 产品管理
const ProductManage =() => import('views/productManage/ProductManage.vue')
const TourismProduct =() => import('views/productManage/children/TourismProduct.vue')
const HotelHomeStay_HomeType =() => import('views/productManage/children/HotelHomeStay_HomeType.vue')
const HotelHomeStay =() => import('views/productManage/children/HotelHomeStay.vue')
const privilegeGoods =() => import('views/productManage/children/privilegeGoods.vue')
const privilegeGoods_norms =() => import('views/productManage/children/privilegeGoods_norms.vue')
const ShopProductList =() => import('views/productManage/children/ShopProductList.vue')

//商城模块
const shopMoudle =() => import('views/shopMoudle/shopMoudle.vue')
const shopOrder =() => import('views/shopMoudle/children/shopOrder.vue')
const shopOrderDetail =() => import('views/shopMoudle/children/shopOrderDetail.vue')
const logisticsManage =() => import('views/shopMoudle/children/logisticsManage.vue')
const shopManage =() => import('views/shopMoudle/children/shopManage.vue')
const Setting = () => import('views/setting/Setting')
const Webset = () => import('views/setting/children/Webset')

const Vipmanage = () => import('views/vipmanage/Vipmanage')
const Viplist = () => import('views/vipmanage/children/Viplist')

//数据代理商
const agentShopMoudle =()=>import('../views/DataAgent/shopMoudle/agentShopMoudle.vue')
const freightTem = ()=>import('../views/DataAgent/shopMoudle/children/freightTem.vue')

const routes = [
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  {
    path: '/',
    redirect: {
      name: 'Login'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '',
    name: Home,
    component: Home,
    children: [
      //供应商
      //控制台
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      //订单管理
      {
        path: '/orderManagement',
        name: 'OrderManagement',
        // /orderManagement/accommodationOrder
        component: OrderManagement,
        children:[
          {
            path: 'accommodationOrder',
            name: 'accommodationOrder',
            component: AccommodationOrder
          },
          {
            path: 'tourOrder',
            name: 'tourOrder',
            component: tourOrder
          },
          {
            path: 'privilegeOrder',
            name: 'privilegeOrder',
            component: privilegeOrder
          },
        ]
      },
      //基础设置
      {
        path: '/basis',
        name: Basis,
        component: Basis,
        children: [
          {
            path: 'brandManage',
            name: 'brandManage',
            component: BrandManage
          },
          {
            path: 'goodsManage',
            name: 'goodsManage',
            component: GoodsManage
          },
          {
            path: 'tourismClassify',
            name: 'tourismClassify',
            component: TourismClassify
          },
          {
            path: 'scenicElements',
            name: 'ScenicElements',
            component: ScenicElements
          },
          {
            path: 'privilegeClassify',
            name: 'privilegeClassify',
            component: PrivilegeClassify
          },
          {
            path: 'card',
            name: 'card',
            component: card
          },
          {
            path: 'shareProfit',
            name: 'shareProfit',
            component: shareProfit
          },
          {
            path: 'tourArea',
            name: 'tourArea',
            component: tourArea
          },
        ]
      },
      //会员
      {
        path: '/vipmanage',
        name: 'Vipmanage',
        component: Vipmanage,
        children: [
          {
            path: 'viplist',
            name: 'Viplist',
            component: Viplist
          }
        ]
      },
      //组织架构
      {
        path: '/organize',
        name: 'Organize',
        component: Organize,
        children: [
          {
            path: 'user',
            name: 'User',
            component: User
          },
          {
            path: 'role',
            name: 'Role',
            component: Role
          },
          {
            path: 'menulist',
            name: 'Menulist',
            component: Menulist
          },
          {
            path: 'power',
            name: 'Power',
            component: Power
          },
          {
            path: 'supplier',
            name: 'Supplier',
            component: Supplier
          }
        ]
      },
      {
        path: '/setting',
        name: 'Setting',
        component: Setting,
        children: [
          {
            path: 'webset',
            name: 'Webset',
            component: Webset
          }
        ]
      },
      //文章管理
      {
        path: '/contentManage',
        name: 'ContentManage',
        component: ContentManage,
        children: [
          {
            path: 'articleManage',
            name: 'ArticleManage',
            component: ArticleManage
          }
        ]
      },
      //产品管理
      {
        path: '/productManage',
        name: 'ProductManage',
        component: ProductManage,
        children: [
          {
            path: 'tourismProduct',
            name: 'TourismProduct',
            component: TourismProduct
          },
          {
            path:'hotelHomeStay',
            name:'HotelHomeStay',
            meta:{
              keepAlive: true, //此组件需要被缓存
              isBack:false, //用于判断上一个页面是哪个
            },
            component:HotelHomeStay,
          },
          {
            path: 'HotelHomeStay_HomeType',
            name: 'HotelHomeStay_HomeType',
            component: HotelHomeStay_HomeType
          },
          {
            path: 'privilegeGoods',
            name: 'privilegeGoods',
            meta:{
              keepAlive: true, //此组件需要被缓存
              isBack:false, //用于判断上一个页面是哪个
            },
            component: privilegeGoods
          },
          {
            path: 'privilegeGoods_norms',
            name: 'privilegeGoods_norms',
            component: privilegeGoods_norms
          },
          {
            path:'shopProductList',
            name:'shopProductList',
            component:ShopProductList
          }
        ]
      },
      //商城模块
      {
        path: '/shopMoudle',
        name: 'shopMoudle',
        component: shopMoudle,
        children: [
          {
            path: 'shopOrder',
            name: 'shopOrder',
            meta:{
              keepAlive: true, //此组件需要被缓存
              isBack:false, //用于判断上一个页面是哪个
            },
            component: shopOrder
          },
          {
            path: 'shopOrderDetail',
            name: 'shopOrderDetail',
            component: shopOrderDetail
          },
          {
            path: 'logisticsManage',
            name: 'logisticsManage',
            component: logisticsManage
          },
          {
            path: 'shopManage',
            name: 'shopManage',
            component: shopManage
          },
        ]
      },
      //数据代理商
      // /agentShopMoudle/freightTem
      {
        path: '/agentShopMoudle',
        name: 'agentShopMoudle',
        component: agentShopMoudle,
        children:[
           {
            path: 'freightTem',
            name: 'freightTem',
            component: freightTem
          },
        ]
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {

//   console.log("to", to)
//   console.log("from", from)
//   next()
// })
export default router
