
export default {
  state: {
    // 登录用户所拥有权限的全部菜单
    menu: []
  },
  // 监听state更新
  getters: {},
  // 操作state
  mutations: {
    getAllMenu (state, obj) {
      state.menu = obj
    }
  },
  actions: {}
}
